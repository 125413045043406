import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Class from "../components/dashboard/class"
import { getMethod, postMethod, putMethod} from "../helper/api"
import 'react-calendar/dist/Calendar.css'
import {navigate} from "gatsby"
import { getWindowItem, isBrowser } from '../helper/globals'
import {Prompt} from '@reach/router'
import { getProfile } from '../state/actions/login/loginAction';
import jwt_decode from "jwt-decode";
import {Cookies} from 'react-cookie';

const Checkout = ({ location, userDetails }) => {

    let [wallet,setWallet] = useState({});
    let [balancePaymentHrs,setBalancePaymentHrs] = useState(0);
    let [cart,setCart] = useState(getWindowItem('cart',false) ? JSON.parse(getWindowItem('cart')) : {});

    let dispatch = useDispatch();

    let cookies = new Cookies();

    if(cookies.get('token')!==undefined && !userDetails){
        let decoded = jwt_decode(cookies.get('token'));
        dispatch(getProfile(decoded.id));
    }

    if(!location.state && !isBrowser){
        location.state = {
            slot : {date : new Date(), start_time:'00:00', end_time:'00:00'},
        }
    }

    if(!location.state && getWindowItem('state',false)){
        location.state = JSON.parse(getWindowItem('state'));
        //booking = getWindowItem('cart',booking);
    }

    let booking = {
        id: null,
        subject: location.state?.subject,
        faculty: location.state?.faculty,
        date: location.state.slot.date,
        // start_time: new Date(location.state.slot.date + 'T' + location.state.slot.start_time + 'Z'),
        // end_time: new Date(location.state.slot.date + 'T' + location.state.slot.start_time + 'Z'),
        time: location.state.slot.time,
        booked_by: userDetails?.id,
        startDate: location.state.slot.startDate,
        endDate: location.state.slot.endDate,
        days:location.state.slot.days,
        slotType: location.state.slotType
    };

    
    

    const [reviews, setReviews] = useState([]);


    async function recharge(cart, balancePaymentHrs){

        navigate('/wallet-payment?v='+window.localStorage.getItem('appVersion'),{state:{cart:cart, balancePaymentHrs:balancePaymentHrs}})

    }

    async function checkOut(){
        let date = new Date(location.state.slot.date.valueOf()+ (1000 * 60 * 60 * ((-1*new Date().getTimezoneOffset())/60))).toISOString().slice(0,10);
        if(location.state.slotType==='Recurring')
            date = new Date(location.state.slot.startDate.valueOf()+ (1000 * 60 * 60 * ((-1*new Date().getTimezoneOffset())/60))).toISOString().slice(0,10);
        let newbooking = {
            subject: location.state.subject.id,
            faculty: location.state.faculty.id,
            // start_time: location.state.slot.date + ' ' + location.state.slot.start_time,
            // end_time: location.state.slot.date + ' ' + location.state.slot.end_time,
            date: date,
            time: location.state.slot.time.join(', '),
            booked_by: userDetails.id,
            payment: '0',
            slot_type: location.state.slotType,
            level: location.state.level,
            duration: location.state.duration,
            //start_date: new Date(location.state.slot.startDate.valueOf()+ (1000 * 60 * 60 * ((-1*new Date().getTimezoneOffset())/60))).toISOString().slice(0,10),
            end_date: new Date(location.state.slot.endDate.valueOf()+ (1000 * 60 * 60 * ((-1*new Date().getTimezoneOffset())/60))).toISOString().slice(0,10),
            days: location.state.slot.days,
            slot: location.state.slot,
            cart:cart
        };

        window.localStorage.setItem('state', JSON.stringify(location.state));

        let resp =  await postMethod('/booking', newbooking);
        //let resp = await putMethod(`/slots/${location.state.slot.id}`,"isBooked=true");
        //window.localStorage.setItem('bookingId',resp.data.cart.id);
        if(resp.data.cart.balancePaymentHrs){
            window.localStorage.setItem('cart', JSON.stringify(resp.data.cart));
            setCart(resp.data.cart);
            
        }
        else
            navigate('/dashboard?booking=success&v='+window.localStorage.getItem('appVersion'));
    }

    useEffect(() => {
        getMyReview()
        async function getMyReview() {
            let sub2 = await getMethod('/reviews', '', { 'postedFor': location.state.faculty?.id, '_limit':5});
            //console.log("sub2:", sub2);
            setReviews(sub2.data);

        }
    }, [reviews.length,location.state.faculty])

    useEffect(()=>{
        async function getWallet() {
            //console.log(userDetails);
            let wallet = await getMethod('/wallets', '', { user_id:userDetails?.id});
            setWallet(wallet.data[0]);
            
        }

        getWallet();
    },[])

    return (
        <Layout title="Book a Lesson - Confirm">
            <Seo title="Level" />
            <div className="container">
            
            <h5 style={{ paddingLeft: '30px' }}>Confirm Details</h5>
            {booking.subject && (<Class booking={booking} />) }
            <div className="row m-sm">
                    <h5>Reviews</h5>
            </div>
            <div style={{ maxHeight: '320px', overflowY: 'auto' }}>
            {reviews && reviews.map(review =>
            (<>
                
                    <div className="row card ">
                    <div className="row m-sm  rating">
                        <span className="p text-black a-self-center">{review.postedBy.firstname}</span>
                        <div className=" ml-auto d-flex a-self-center rating">
                            <i className="icon icon-Star_Fill fill-yellow a-self-center"></i>
                            <span className="p text-black a-self-center">{review.rating}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col bg-lightBlue p-sm border-radius">
                            <label className="text-black">{review.feedback}</label>
                        </div>
                    </div>
                    </div>
                
            </>))}
            </div>

            <div className="row">
                <div className="btn btn-lg btn-blue" >Wallet Balance: {wallet?.balance_hrs?wallet.balance_hrs:0} hrs</div>
            
                {cart.balancePaymentHrs>0 && 
                    <div className="row d-block m-sm">
                        <div className="btn  btn-lg btn-blue" >Payment: {cart.balancePaymentHrs} {cart.balancePaymentHrs>1?'hrs':'hr'}</div>
                    </div>}
                {cart.balancePaymentHrs>0 && 
                    <div className="row d-block m-sm">
                        <div className="btn  btn-primary btn-lg btn-blue" onClick={()=>recharge(cart, cart.balancePaymentHrs)}>Pay Now and Confirm</div>
                    </div>}
                </div>
            {!cart.balancePaymentHrs && <div className="row d-block m-sm">
                <div className="btn btn-primary btn-lg btn-blue" onClick={()=>checkOut()}>Checkout</div>
            </div>}
            
            </div>
        </Layout>
    );
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user }), null)(Checkout);